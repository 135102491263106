import logo from "./logo.webp"

const clientData = {
    client_entity: 13,
    attorney_firm: 'Alliance Business Law',
    attorney_name: 'Kimberly Simpson',
    attorney_number: '',
    attorney_email: 'ksimpson@alliancebusinesslaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_gravis_law+(720p).mp4',
    mainColor: '#117ac9',
    secondaryColor: '#f49231',
    siteLink: '',
    logo
}

export default clientData